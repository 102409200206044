import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';


import taglineList from 'data/taglineList.json';
const tagline = taglineList.taglineList[Math.floor(taglineList.taglineList.length*Math.random())];
const taglineURL = "https://www.google.com/search?q="+encodeURIComponent(tagline+" tagline");

class Footer extends Component {
  render() {
    return (
        <Container maxWidth="md" sx={{mb: 16}}>
            <Divider sx={{mt: 8, background: '#000'}} />
            <Grid container spacing={2} alignItems="center" justifyContent="center" my={{md: 4, xs: 2}}>
                <Grid item xs={6}>
                    <Typography textAlign="left" variant="h6">
                        © {new Date().getFullYear()} Nick Stepro &#8226; <Link href="https://www.linkedin.com/in/nickstepro/" target="_blank">linkedin.com/in/nickstepro</Link>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography textAlign="right" variant="h6">
                        <Link href={taglineURL} target="_blank">{tagline}</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
  }
}
export default Footer;