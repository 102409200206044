import React, { Component } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Navigation from 'components/NavigationSimple';
import {Link as RouterLink} from 'react-router-dom';
// import Footer from 'components/Footer';
import { Parser } from 'commonmark';
import ReactMarkdown from 'react-markdown';
import AWS from 'aws-sdk';
import matter from 'gray-matter';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeHighlight from 'rehype-highlight';
import ModalImage from "react-modal-image";
import 'styles/Notes.scss';

const s3Bucket = process.env.REACT_APP_AWS_BUCKET_NAME;
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

var parser = new Parser();

function renderImages(content) {

    // Process dependent images
    content.split('![[').forEach((str,i)=>{
        if (i>0) {
            var imgName = str.split(']]')[0];
            var params = {Bucket: s3Bucket, Key: imgName};
            var imgUrl = s3.getSignedUrl('getObject', params);
            content = content.replace(
                `![[${imgName}]]`, 
                `![${imgName.split('.')[0]}]('${imgUrl}')`
            );
        }
    });
    return content;

}

function findHashtags(content) {
    var regexp = /\B\#\w\w+\b/g
    var result = content.match(regexp);
    var uniqueMatches = [...new Set(result)];
    if (uniqueMatches) {
        uniqueMatches.forEach(r=>{
            content = content.replaceAll(r, `*${r}*`);
        });
        return content
    } else {
        return content;
    }
}

function imageRender(props) {
    var srcNew = props.src.replaceAll("'", "");
    return (
        <ModalImage
            small={srcNew}
            large={srcNew}
            alt={props.alt}
            className="image-modal"
            hideDownload={true}
            hideZoom={false}
            style={{maxWidth:'90%'}}
        />
    );
};

function hashtagRender(props) {
    if (typeof props.children[0] === 'string' && props.children[0].substring(0,1) == '#') {
        return (
            <span className="hashtag">
                {props.children}
            </span>
        );
    } else {
        return (
            <em>
                {props.children}
            </em>
        );
    }
}

function checkboxRender(props) {
    var className = "checkbox "+(props.checked?"checked":"");
    if (props.type == 'checkbox') {
        return (
            <span className={className} />
        )
    }
    return ReactMarkdown.renderers
}

function getHtmlId(title) {
    return title.trim().toLowerCase().replace(/[^a-z0-9]+/g, "-");
}

function tocRender(props) {
    var parent = props.children[0];
    var children = props.children.slice(1);
    return (
        <li>
            <a href={"#"+getHtmlId(parent)}>
                {parent}
            </a>
            {children}
        </li>
    )
}

function hRender(props) {
    var id = getHtmlId(props.children[0]);
    return  React.createElement(
        props.node.tagName, {id}, props.children
    )
}

function getTitle(content) {
    return content.substring(0, content.indexOf("\n")).replace("#","").trim();
}

let maxTOCLevel = 0; 

function buildTOC(content, config) {
    try {
        let maxTOCLevel = config.maxTOC || 9999;
        // PARSE CONTENT AND PULL OUT TOC
        var parsed = parser.parse(content);
        var toc = [];
        var tocIdx = 0;
        var walker = parsed.walker();
        var event, node;
        while ((event = walker.next())) {
        node = event.node;
        if (event.entering && node.type === 'heading') {
            var h = node.firstChild;
            var hContent = h.literal;
            while(h.next !== null) {
                h = h.next;
                hContent = hContent + h.literal;
            }
            toc.push({
                idx: tocIdx,
                level: node.level,
                title: hContent
            })
            tocIdx++;
        }
        }
        
        // ADD TITLE TO MD
        var title = toc.shift();
        let md = "# " + title.title + '\n', sep = ' ';

        // CONVERT TO MD (KEEP ONLY DESIRED LEVELS)
        toc = toc.filter((d)=>{return d.level <= maxTOCLevel});
        if (toc.length>0) {
            var minLevel = toc.reduce(function(prev, curr) {
                return prev.level < curr.level ? prev : curr;
            }).level;
            
            toc.forEach(t=>{
                md = `${md}${sep.repeat(((t.level-(minLevel-1))*2))}- ${t.title} \n`;
            });
        }
        return {data: toc, md: md}
    } catch(e) {
        console.log(e);
        return {data: null, md: ""}
    }
}

function setDocumentTitle(title, lastModified, navType) {
    if (navType=="arc") {
        return title + " " + lastModified.toLocaleDateString().replaceAll("/",".");
    } else {
        return "Notes: "+title+" (Nick Stepro)";
    }
}

class Notes extends Component {

    constructor(props) { super(props); this.state = { mdText: null } }
    componentDidMount() {

        
        console.log(this.props);
        
        // Ftech file to S3 (overwrite if exists)
        const params = {
            Bucket: s3Bucket,
            Key: `${this.props.match.params.id}.md`
        }
        s3.getObject(params, (err, data) => {
            if (err) { 
                console.log(err); 
                this.setState({mdText: '# File not found :('})
            }
            else { 
                var m = matter(data.Body.toString('utf-8'));
                this.setState({ mdTextTOC: buildTOC(m.content, m.data).md });
                if (m.data.arc === true) { 
                    this.setState({navType: "arc"}) 
                } else {
                    this.setState({navType: "nick"})
                }
                document.title = setDocumentTitle(getTitle(m.content), data.LastModified, this.state.navType);
                this.setState({gitURL: m.data.gitURL});
                // m.content = m.content.slice(m.content.indexOf("\n"));
                m.content = renderImages(m.content);
                m.content = findHashtags(m.content);
                this.setState({ mdText:m.content.slice(m.content.indexOf("\n")) });
            }
        });
    }

  render() {
    // this.props.match.params.id
    return (
        <Container maxWidth="md">
            <Navigation type={this.state.navType} />
            <ReactMarkdown children={this.state.mdTextTOC} className="markdown-holder notes toc" components={{ "li": tocRender }} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeHighlight]} />
            <ReactMarkdown linkTarget="_blank" children={this.state.mdText} className="markdown-holder notes" components={{ "img": imageRender, "em": hashtagRender, "input": checkboxRender, "h1": hRender, "h2": hRender, "h3": hRender, "h4": hRender, "h5": hRender, "h6": hRender}} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeHighlight]} />
            <Grid container spacing={2} alignItems="center" justifyContent="center" my={{md: 4, xs: 2}}>
                <Typography className="note-footer" textAlign="center" variant="p" mb={0}>
                    &copy;{ new Date().getFullYear() } 
                    { this.state.navType=="nick" && <RouterLink to="/"> Nick Stepro</RouterLink> }
                    { this.state.navType=="arc" && <a href="https://arcadia.io/" target="_blank"> Arcadia.io</a> }
                    { !(this.state.gitURL == null) && <span> &bull; <a href={this.state.gitURL} target="_blank"> Edit on Git</a> </span> }
                </Typography>
            </Grid>
            
        </Container>
    )
  }
}
export default Notes;