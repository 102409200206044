import React, { Component, Suspense } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import AWS from 'aws-sdk';
import matter from 'gray-matter';

const s3Bucket = process.env.REACT_APP_AWS_BUCKET_NAME;
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

class Project extends Component {

    constructor(props) { super(props); this.state = { mdText: null, pageTitle: null, pageMeta: null } }
    componentDidMount() {
        s3.getObject({Bucket: s3Bucket, Key: `${this.props.match.params.id}.md`}, (err, data) => {
            if (err) { console.log(err); }
            else { 
                var m = matter(data.Body.toString('utf-8'));
                // Remove header from content
                this.setState({mdText: m.content.slice(m.content.indexOf("---")+3)});
                // Get header for title section
                this.setState({pageTitle: m.content.slice(1,m.content.indexOf("\n"))});
                // Add metadata
                this.setState({pageMeta: m.data});
            }
        });
    }
  render() {    
    const ProjectComponent = React.lazy(() => 
            import(`components/projects/${this.props.match.params.id}.jsx`)
            .catch(() => import(`components/projects/_vanilla-project.jsx`))
    );
    
    return (
        <Container maxWidth="md">
            <Navigation />
            <Typography textAlign="center" variant="h1" mb={0}>{this.state.pageTitle}</Typography>
            <Divider sx={{mb: 8, background: '#000'}} />
            <Suspense fallback={<div>Loading content...</div>}>
                <ProjectComponent mdTextIn={this.state.mdText} pageMeta={this.state.pageMeta} />
            </Suspense>
            <Footer />
        </Container>
    )
  }
}
export default Project;