import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';

import {Link as RouterLink} from 'react-router-dom';
import AWS from 'aws-sdk';
import matter from 'gray-matter';

const s3Bucket = process.env.REACT_APP_AWS_BUCKET_NAME;
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

class Projects extends Component {

    constructor(props) { super(props); this.state = { projectList: [] } }
    componentDidMount() {

        s3.getObject({Bucket: s3Bucket, Key: "grimace-project-list.md"}, (err, data) => {
            if (err) { 
                console.log(err); 
            }
            else { 
                var m = matter(data.Body.toString('utf-8'));
                
                var projectList = [];
                for (var project in m.data.projects) {
                    var p = m.data.projects[project]
                    projectList.push({
                        id: project,
                        emoji: p.emoji!==undefined?p.emoji.repeat(99):"",
                        text: project+".md",
                        desc: p.desc,
                        title: p.title
                    })
                }
                this.setState({projectList: projectList});
            }
        });
        
    }

  render() {
    return (
        <Container maxWidth="md">
            <Navigation />
            <Typography textAlign="center" variant="h1" component="h1" gutterBottom mb={0}>
                NONSENSE.
            </Typography>
            <Grid container spacing={2} alignItems="center" justifyContent="center" my={{md: 4, xs: 2}}>
                {this.state.projectList.map(({id, title, img, desc, emoji}) => (
                        <Grid item xs={6} md={4} key={id}>
                            <RouterLink to={'p/'+id}>
                                <Box className='project-tile'>
                                    <Box className='project-tile-background'>{emoji}</Box>
                                    <Box className='project-tile-title'><Typography textAlign="left" variant="h4">{title}</Typography></Box>
                                    <Box className='project-tile-desc'><Typography textAlign="right" variant="body1">{desc}</Typography></Box>
                                </Box>
                            </RouterLink>
                        </Grid>
                ))}
            </Grid>
            <Footer />
        </Container>
    )
  }
}
export default Projects;