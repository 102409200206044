import React, { Component } from 'react'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {Link as RouterLink} from 'react-router-dom';
import arcImg from 'images/arc-horiz-color.png';


export default class MenuExampleVertical extends Component {
  state = { activeItem: 'inbox' }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })


  render() {
      
      if (this.props.type == 'arc') {
        return (
          <Container maxWidth="md">
            <Typography textAlign="center" sx={{mt: 2}}>
              <a href="https://arcadia.io/" target="_blank"><img className="arc-img" alt="Arcadia.io" src={arcImg} /></a>
            </Typography>
          </Container>
        )
      } else if (this.props.type == 'nick') {
        return (
          <Container maxWidth="md">
              <Typography textAlign="center" sx={{mt: 2}}><RouterLink to="/"><Button size="large" className="title" variant="outlined">NICK STEPRO</Button></RouterLink></Typography>
          </Container>
        )
      } else {
        return (
          <Container maxWidth="md" />
        )
      }
  }
}