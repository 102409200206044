import React from 'react';
import './App.scss';

// Themes
import { ThemeProvider } from '@mui/material'
import theme from './themes/mui-harsh'

// Components
import Contact from './components/Contact';
import Home from './components/Home';
import Projects from './components/Projects';
import Project from './components/Project';
import Notes from './components/Notes';

// Router
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <Switch>
          <Route exact path="/"><Home /></Route>
          <Route path="/projects"><Projects /></Route>
          <Route path="/contact"><Contact /></Route>
          <Route path="/p/:id" render={(props) => <Project {...props} />}></Route>
          <Route path="/note/:id" render={(props) => <Notes {...props} />}></Route>
          <Route path="/n/:id" render={(props) => <Notes {...props} />}></Route>
      </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
